export default {
  global: {
    body: {
      fontFamily: 'Roboto, sans serif',
      letterSpacing: '1.2px',
    },
    space: {
      px: '4px',
    },
  },
};
